import * as React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import resumeFile from '../../content/resume.pdf';

import Layout from "../components/layout"
import Seo from "../components/seo"

const AboutPage = (props) => {
  return (
    <Layout>
      <Seo title="About" />

      <div className="flex flex-col gap-8">
        <h1 className="mb-10">About</h1>

        <p className="leading-normal">
          I'm Radoslav Genchev, a software engineer who likes testing new technologies and building side projects. Clean and testable code is what I'm really passionate about. I build, test and deploy mostly Rails apps. Recently, I started blogging. And when I'm not in front of the laptop, I spend my time traveling, hiking, reading or taking pics. You can check my resume <a href={resumeFile} target="_blank" rel="noopener noreferrer" title="Resume">here</a>.
        </p>

        <p>
          Let's get in touch! Feel free to <a href="https://twitter.com/r_genchev" title="Message me on Twitter" target="_blank" rel="noopener noreferrer">message me on Twitter</a> if you're in the mood.
        </p>

        <Img
          fluid={props.data.file.childImageSharp.fluid}
          style={{
              borderRadius: "1%",
              zIndex: -1
          }}
          alt="avatarImage"
          title="Radoslav Genchev"
        />
      </div>
    </Layout>
  )
}

export default AboutPage

export const pageQuery = graphql`
	query {
	    file(relativePath: { eq: "about_new.jpg"}) {
            childImageSharp {
                fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
                }
            }
	    }
	}
`
